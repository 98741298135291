import React from "react";

import Image from "../Image";
import VideoPlayer from "../VideoPlayer";

export default function ImageOrVideo({ node, imageProps, videoProps, sx }) {
  return node[0].asset ? (
    <Image
      fluid={node[0].asset.fluid}
      alt={node[0].alt}
      extension={node[0].asset.extension}
      sx={sx}
      {...imageProps}
    />
  ) : (
    <VideoPlayer
      src={node[0].file.asset.url}
      poster={node[0].poster}
      sx={sx}
      {...videoProps}
    />
  );
}
