import React from "react";
import {
  motion,
  useMotionValue,
  useViewportScroll,
  AnimatePresence,
} from "framer-motion";
import { useThemeUI } from "@theme-ui/core";

import GridRow from "../../commons/GridRow";
import Box from "../../commons/Box";
import ImageOrVideo from "../../commons/ImageOrVideo";
import RichTextRenderer from "../../commons/RichTextRenderer";
import Button from "../../commons/Button";
import useKeyPress from "../../../utils/useKeyPress";
import { linkResolver } from "../../../utils/links";

export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
export const closeSpring = { type: "spring", stiffness: 300, damping: 35 };

function getLayoutStyle(direction) {
  switch (direction) {
    case "center":
      return {
        gridTemplateColumns: "0 3fr 5fr 0",
        gridTemplateAreas: [
          `'. text text .'
          '. media media .'`,
          `'. text . .'
          '. media media .'`,
        ],
      };
    case "fullscreen":
      return {
        gridTemplateColumns: "0 3fr 5fr 0",
        gridTemplateAreas: `'. text . .'
          'media media media media'`,
        ".media": {
          gridRowStart: 1,
          my: [-5, "-gridGap"],
        },
      };
    case "left":
      return {
        gridTemplateColumns: "0 5fr 3fr 0",
        gridTemplateAreas: [
          `'. text text .'
          '. media media .'`,
          "'. media text'",
        ],
      };
    case "right":
    default:
      return {
        gridTemplateColumns: "0 3fr 5fr 0",
        gridTemplateAreas: [
          `'. text text .'
          '. media media .'`,
          "'. text media'",
        ],
      };
  }
}

export default function MediaBlock({
  _rawDescription,
  buttons,
  media,
  ...otherProps
}) {
  const isVideo = !!media.media[0].file;
  const { theme } = useThemeUI();
  const [isVideoPlaying, setVideoPlaying] = React.useState();
  const { scrollY } = useViewportScroll();
  const isEscPressed = useKeyPress("Escape");

  React.useEffect(
    () =>
      scrollY.onChange(() => {
        setVideoPlaying(false);
      }),
    [scrollY]
  );

  React.useEffect(() => {
    setVideoPlaying(false);
  }, [isEscPressed]);

  const zIndex = useMotionValue(isVideoPlaying ? theme.zIndices.modal : 0);

  const handleVideoPlay = React.useCallback(() => {
    setVideoPlaying(true);
  }, []);

  const handleVideoPause = React.useCallback(() => {
    setVideoPlaying(false);
  }, []);

  return (
    <GridRow
      as="section"
      sx={{
        py: [5, "gridGap"],
        background: "white",
        textAlign: ["center", "start"],
        ...getLayoutStyle(media.direction),
      }}
    >
      <GridRow.Col sx={{ position: "relative", zIndex: 1, gridArea: "text" }}>
        {_rawDescription && <RichTextRenderer blocks={_rawDescription} />}
        {!!buttons.length && (
          <Box sx={{ mt: 4 }}>
            {buttons.map(linkResolver).map((link) => (
              <Button key={link.to || link.href} {...link} />
            ))}
          </Box>
        )}
      </GridRow.Col>
      <GridRow.Col
        className="media"
        sx={{
          gridArea: "media",
          alignSelf: "center",
          ...(isVideo && { minHeight: [0, "50vh"] }),
          ...(isVideoPlaying && {
            paddingBottom: ["56.25%", 0],
          }),
        }}
      >
        {isVideo && (
          <AnimatePresence>
            {isVideoPlaying && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={isVideoPlaying ? openSpring : closeSpring}
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: theme.zIndices.modal,
                  backgroundColor: "black",
                }}
                onAnimationStart={() => {
                  zIndex.set(theme.zIndices.modal);
                }}
                onAnimationComplete={() => {
                  zIndex.set(0);
                }}
              />
            )}
          </AnimatePresence>
        )}
        <Box
          sx={(theme) => ({
            ...(isVideoPlaying && {
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              overflow: "hidden",
              padding: "gridGap",
              zIndex: "modal",
            }),
          })}
          onClick={handleVideoPause}
        >
          <motion.div
            layoutTransition={isVideoPlaying ? openSpring : closeSpring}
            style={{ position: "relative", zIndex }}
          >
            <ImageOrVideo
              node={media.media}
              videoProps={{
                onPlay: handleVideoPlay,
                isPlaying: isVideoPlaying,
              }}
              sx={{
                ...(isVideo && {
                  height: ["auto", "50vh"],
                  objectFit: "cover",
                }),
                ...(isVideoPlaying && {
                  height: "auto",
                }),
              }}
            />
          </motion.div>
        </Box>
      </GridRow.Col>
    </GridRow>
  );
}
