import React from "react";
import PropType from "prop-types";
import { useInView } from "react-intersection-observer";

import Box from "../Box";
import { cover } from "../../../utils/styles";

import { ReactComponent as Play } from "../../../images/icons/triangle.svg";

export default function VideoPlayer({
  sx,
  src,
  background,
  controls = true,
  muted,
  autoplay = false,
  loop,
  immediate = false,
  inline = false,
  sources,
  children,
  onPlay,
  isPlaying,
  poster,
  ...otherProps
}) {
  const playerRef = React.useRef();
  const sourcesRef = React.useRef(sources);
  const [inViewRef, isInView] = useInView({
    triggerOnce: true,
    rootMargin: "20% 0px",
  });

  const handleClick = React.useCallback(() => {
    const player = playerRef.current;

    player.play();
    onPlay && onPlay();
  }, [onPlay]);

  React.useEffect(() => {
    if ((!isInView && !immediate) || !autoplay) return;

    const player = playerRef.current;

    player.play();
    onPlay && onPlay();
  }, [autoplay, immediate, isInView, onPlay]);

  React.useEffect(() => {
    const player = playerRef.current;

    if (isPlaying) {
      player.play();
    } else {
      player.pause();
    }
  }, [isPlaying]);

  // Reload video if sources change
  React.useEffect(() => {
    if (!sourcesRef.current) return;

    if (sourcesRef.current.mp4 !== sources.mp4) {
      playerRef.current.load();
    }
    sourcesRef.current = sources;
  }, [sources]);

  return (
    <Box
      ref={inViewRef}
      sx={{ position: "relative", width: "100%", height: "100%" }}
    >
      <Box
        as="video"
        ref={playerRef}
        sx={{ display: "block", width: "100%", ...sx }}
        muted={muted}
        loop={loop}
        controls={isPlaying}
        playsInline={inline}
        src={src}
        onPlay={onPlay}
        poster={poster.asset.fixed.src}
        {...otherProps}
      >
        {sources && <source src={sources.mp4} type="video/mp4" />}
      </Box>
      <Box
        onClick={handleClick}
        sx={{
          ...cover,
          zIndex: 1,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transitionDuration: (theme) => theme.transitions.normal,
          transitionProperty: "all",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          svg: {
            transitionDuration: (theme) => theme.transitions.fast,
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            svg: {
              transform: "scale(1.1)",
            },
          },
        }}
        style={{ display: isPlaying ? "none" : "flex" }}
      >
        <Play />
      </Box>
    </Box>
  );
}

VideoPlayer.propTypes = {
  width: PropType.number,
  height: PropType.number,
};
